import React from 'react';

const Loading = ({ isFlex, style, needsBackground, customStyle }) => {
  return (
    <div
      style={{ ...style, background: needsBackground ? 'var(--color-dashboard-bg)' : 'transparent' }}
      className={isFlex ? 'spinner-container-flex' : 'spinner-container'}
    >
      <div style={style} className="spinner-border custom-size text-custom-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
