import Loading from '../components/common/loading';
import { protocolManifest } from '../components/manifest/manifest';
import { getProtocolManifestValue, prettifyProtocolKey, renderOgImage } from '@utils/utils';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const DynamicSEOForProtocols = dynamic(() => import('../components/seo-for-protocols'), {
  ssr: false,
});

const DynamicWrapper = dynamic(() => import('../layout/wrapper'), {
  ssr: false,
});

const ProtocolPage = ({ protocolKey, ogImage }) => {
  const [protocolConfig, setProtocolConfig] = useState(null);
  const [LandingPageComponent, setLandingPageComponent] = useState(null);
  const [ListAreaComponent, setListAreaComponent] = useState(null);
  const [error, setError] = useState(null);
  const router = useRouter();

  const goToSearch = () => {
    const newPath = `/`;
    router.push(newPath, undefined);
  };

  useEffect(() => {
    const loadProtocolConfig = async () => {
      try {
        const protocolModule = await protocolManifest[getProtocolManifestValue(protocolKey)]();

        const protocolConfig = protocolModule.default;
        setProtocolConfig(protocolConfig);

        const LandingPage = protocolConfig.landingPageComponent;
        const ListArea = protocolConfig.listAreaComponent;
        setLandingPageComponent(() => LandingPage);
        setListAreaComponent(() => ListArea);
      } catch (error) {
        setError(error);
        console.error(`Error loading protocol config for key ${protocolKey}:`, error);
      }
    };

    loadProtocolConfig();
  }, [protocolKey]);

  if (error) {
    return (
      <DynamicWrapper
        style={{
          background: 'black',
        }}
      >
        {' '}
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            minHeight: '100vh',
            flexDirection: 'column',
          }}
        >
          <p style={{ fontSize: 18 }} className="mb-0">
            Unable to find the integration with id: {prettifyProtocolKey(protocolKey)}, please try again from the
            homepage.
          </p>
          <button
            className={'edu-btn btn-medium d-flex justify-content-center align-items-center'}
            id="overview-tab"
            onClick={() => goToSearch()}
            type="button"
            role="tab"
            aria-controls="search"
          >
            <p className="mb-0"> Back to Homepage</p>
          </button>
        </div>
      </DynamicWrapper>
    );
  }

  if (!protocolConfig || !LandingPageComponent || !ListAreaComponent) {
    return (
      <DynamicWrapper style={{ background: 'black' }}>
        {' '}
        <Loading />
      </DynamicWrapper>
    );
  }

  const {
    dashboardColumnData,
    dashboardColumnDataExited,
    dashboardColumnTokens,
    dashboardColumnTokensExited,
    keys,
    config,
  } = protocolConfig;

  return (
    <DynamicWrapper customStyle={config.customStyle}>
      <DynamicSEOForProtocols />
      <LandingPageComponent
        columnDataCurrent={dashboardColumnData}
        columnDataExited={dashboardColumnDataExited}
        columnTokensCurrent={dashboardColumnTokens}
        columnTokensExited={dashboardColumnTokensExited}
        config={config}
        ListAreaComponent={ListAreaComponent}
        keys={keys}
      />
    </DynamicWrapper>
  );
};

export async function getServerSideProps({ params }) {
  const { protocolKey } = params;
  const ogImage = renderOgImage();

  return {
    props: {
      protocolKey: protocolKey[0],
      ogImage,
    },
  };
}

export default ProtocolPage;
